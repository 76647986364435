<template>
  <VDialog
    v-model="visibility"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <VCard>
      <div class="header">
        <VContainer>
          <VRow aling="center">
            <img src="@/assets/images/logo-dark.svg" alt="" />
          </VRow>
        </VContainer>
      </div>
      <VContainer>
        <VRow class="my-5 d-print-none ">
          <VBtn plain color="primary" @click="onClose"
            ><VIcon left>mdi-arrow-left</VIcon>{{ $t("btn.back") }}</VBtn
          >
        </VRow>
        <VRow class="mb-5" justify="space-between" align="center">
          <VCol>
            <h2 class="font-weight-medium">
              {{ $t("cabinet.clientReport") }}:
              <i>{{ user.name || "--" }} {{ user.surname || "--" }}</i>
            </h2>
          </VCol>
          <div>
            <VSubheader class="text-transform-capitalize">
              {{ DateToText }}
            </VSubheader>
          </div>
          <div class="mt-1 d-print-none aling-center d-flex">
            <VBtn color="primary" @click="printPage">
              <VIcon left>mdi-printer</VIcon>
              {{ $t("cabinet.print") }}
            </VBtn>
          </div>
        </VRow>

        <VSimpleTable class="mt-10">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("table.description") }}
                </th>
                <th class="text-left">
                  {{ $t("table.numbers") }}
                </th>
                <th class="text-left">
                  {{ $t("table.price") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{$t('cabinet.sending')}}</td>
                <td>{{ totalSend }}</td>
                <td class="green--text">{{ getTotalSendPrice }} {{$t('paymentPlan.uah')}}</td>
              </tr>
              <tr>
                <td>{{$t('cabinet.amountSpaceStorage')}}</td>
                <td>{{ deportSizeHours.h_m || "0" }} m<sup>3</sup>/h</td>
                <td class="green--text">{{ getTotalHoursPRice }} {{$t('paymentPlan.uah')}}</td>
              </tr>
              <tr>
                <td>{{$t('cabinet.currentStorageCapacity')}}</td>
                <td>{{ deportCurrentSize || "0" }} m<sup>3</sup></td>
                <td class="green--text"></td>
              </tr>
              <tr>
                <th scoped="2">{{$t('cabinet.total')}}</th>
                <td></td>
                <th>{{ getTotalPrice }} {{$t('paymentPlan.uah')}}</th>
              </tr>
            </tbody>
          </template>
        </VSimpleTable>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script>
import usersService from "@/services/request/users/usersService";
import metricsService from "@/services/request/metrics/metricsService";
import notifications from "@/mixins/notifications";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import moment from "moment";
import { mapGetters  } from 'vuex';

export default {
  mixins: [notifications],
  data: () => ({
    user: [],
    date: new Date().toISOString().substr(0, 7),
    deportSizeHours: [],
    amount: "",
    totalSend: "",
    userPlanInfo: {},
    deportSizeCurrent: []
  }),
  props: {
    chosenDate: {
      require: false
    },
    userUuid: {
      require: true
    },
    visible: {
      require: true
    }
  },
  watch: {
    chosenDate: {
      deep: true,
      handler(e) {
        // orderDialog(e);
        if (e) {
          this.date = e;
        }
      }
    }
  },
  async mounted() {
    if (this.chosenDate) {
      this.date = this.chosenDate;
    }
    await this.getUser();
    this.getMetricsDeportSizeCurrent()
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    deportCurrentSize(){
      if(this.deportSizeCurrent.m){
        return this.deportSizeCurrent.m.toFixed(2)
      } else{
        return '0'
      }
    },
    ...mapGetters([
      "currentLanguage"
    ]),
    DateToText() {
      return moment(this.date)
        .locale(this.currentLanguage.key)
        .format(" MMMM YYYY");
    },
    getTotalPrice() {
      if (this.getTotalSendPrice && this.getTotalHoursPRice) {
        return this.getTotalSendPrice + this.getTotalHoursPRice;
      } else {
        return 0;
      }
    },
    getTotalSendPrice() {
      if (this.totalSend && this.userPlanInfo.price_delivery) {
        return Number(this.totalSend) * Number(this.userPlanInfo.price_delivery);
      }
      return 0;
    },
    getTotalHoursPRice() {
      if (this.deportSizeHours.h_m && this.userPlanInfo.price_hour_meter) {
        return +(
          Number(this.deportSizeHours.h_m) * Number(this.userPlanInfo.price_hour_meter)
        ).toFixed(2);
      } else {
        return 0;
      }
    }
  },
  methods: {
    async getMetricsDeportSizeCurrent() {
      try {
          this.deportSizeCurrent = await metricsService.getMetricsDeportSizeCurrentForClient(this.user.uuid);
          //   // orderDialog(this.deportSizeCurrent);
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    onClose() {
      this.$emit("close");
      this.user = [];
    },
    printPage() {
      window.print();
    },
    async getUser() {
      try {
        const newUser = await usersService.getUserByUUID(this.userUuid);
        this.user = newUser[0];
        console.log(this.user)
        this.getMetricsDeportSizeHours(this.user);
        this.getSendDeliveries(this.user);
        this.getPaymentPlan(this.user);
      } catch (e) {
        console.log(e)
        this.setErrorNotification(e);
      }
    },
    async getPaymentPlan(user) {
      try {
        if (user.uuid_user_plan) {
          this.loading = true;
          const planItem = await paymentPlansService.usersPlansInfo(user.uuid_user_plan);
          if (!planItem) {
            this.setErrorNotification("Цей клієнт поки немає тарифного плану");
          } else {
            this.userPlanInfo = planItem;
          }
          this.loading = false;
        }
      } catch (e) {
        this.setErrorNotification(this.$t('paymentPlan.no_payment_plan'));
      }
    },

    async getMetricsDeportSizeHours(user) {
      try {
        const params = [];
        params.year = this.date.substr(0, 4);
        params.month = this.date.substr(5, 2);
        params.uuid_deport = user.uuid_deport;
        params.uuid_user = user.uuid;
        // orderDialog(this.user);
        this.deportSizeHours = await metricsService.getMetricsDeportSizeHours(params);

        if (this.deportSizeHours.h_m) {
          this.amount = (this.deportSizeHours.h_m * 0.2).toFixed(2);
          this.amount = parseFloat(this.amount, 10);
          // orderDialog(this.amount);
        } else {
          this.amount = null;
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async getSendDeliveries(user) {
      try {
        const params = [];
        params.id_state = 420;
        params.year = this.date.substr(0, 4);
        params.month = this.date.substr(5, 2);
        params.uuid_user = user.uuid;
        // orderDialog(this.user)
        const deliveries = await metricsService.getMetricsDeliveriesDelivered(params);
        // orderDialog(deliveries);
        this.totalSend = deliveries.message.substr(7);
        // orderDialog(this.total);
      } catch (e) {
        // orderDialog(e);
        this.setErrorNotification(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  background: darken(white, 5);
  border-bottom: 1px solid darken(white, 10);
  padding: 10px 0;
}
@media print {
  .container {
    padding: 10px 20px !important;
  }
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
