<template>
  <VCard class="pa-2" style="height:100px">
      <VBtn text color="primary" :small="$vuetify.breakpoint.mdAndDown">
        <v-icon left :small="$vuetify.breakpoint.mdAndDown">
          mdi-move-resize-variant
        </v-icon>
       {{$t('cabinet.currentStorageCapacity')}}
      </VBtn>
    <VRow class="size-hours" v-if="deportSizeCurrent">
      <VCol class="ccol">
        <div class="size-hours-header" :class="{'text-caption' :$vuetify.breakpoint.xs}">m<sup>3</sup>:</div>
      </VCol>
      <VCol>
        <div class="size-hours-data" :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ deportCurrentSize }}</div>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
import metricsService from "../../services/request/metrics/metricsService";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import loader from "../../mixins/loader";

export default {
  mixins: [user, notifications, loader],

  data: () => ({
    deportSizeCurrent: []
  }),
  async mounted() {
    console.log(this.$router.lang)
    this.getMetricsDeportSizeCurrent();
  },
  computed:{
    deportCurrentSize(){
      if(this.deportSizeCurrent.m){
        return this.deportSizeCurrent.m.toFixed(2)
      } else{
        return '0'
      }
    }
  },
  methods: {
    async getMetricsDeportSizeCurrent() {
      try {
        if (this.permissions.can_see_sizy_data) {
          this.deportSizeCurrent = await metricsService.getMetricsDeportSizeCurrent();
          //   // orderDialog(this.deportSizeCurrent);
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    }
  }
};
</script>

<style></style>
