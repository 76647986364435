<template>
  <VCard class="mt-2">
    <VCardText>
      <VRow align="center" justify="space-between">
        <VCol cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'text-center' : 'text-left'" md="12" sm="12"  lg="auto">
            <VBtn color="primary" outlined @click="$emit('showReportDialog')" >
              <v-icon left >
                mdi-chart-timeline
              </v-icon>
              {{$t('cabinet.clientReportBtn')}}
            </VBtn>
        </VCol>

        <VCol cols="12" :class="$vuetify.breakpoint.mdAndDown ? 'text-center pt-0' : 'text-right'" md="12" sm="12"  lg="7">
          {{$t('cabinet.clientReportInfo')}}
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>

<script>
export default {};
</script>

<style></style>
