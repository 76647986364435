<template>
  <VCard class="info-card mt-2">
    <VCardText>
      <VRow no-gutters>
        <VCol cols="12" xl="6" lg="6" md="6" sm="6">
          <VBtn text color="primary" :small="$vuetify.breakpoint.mdAndDown" >
            <v-icon left :small="$vuetify.breakpoint.mdAndDown">
              mdi-information-outline
            </v-icon>
            {{$t('cabinet.storage')}}
          </VBtn>
          <VRow class="size-hours" v-if="deportSizeHours" align="center" :justify="$vuetify.breakpoint.xs ? 'space-between' :'start'" :no-gutters="$vuetify.breakpoint.xs" :class="{'px-8' :$vuetify.breakpoint.xs}" >
            <VCol class="pr-0" cols="auto" >
              <div class="size-hours-header" :class="{'text-caption' :$vuetify.breakpoint.xs}">м<sup>3</sup>/h:</div>
            </VCol>
            <VCol cols="auto">
              <div class="size-hours-data" :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ getDeportSizeHour  }}</div>
            </VCol>
          </VRow>
          <VRow class="size-hours" align="center" :justify="$vuetify.breakpoint.xs ? 'space-between' :'start'" :no-gutters="$vuetify.breakpoint.xs" :class="{'px-8' :$vuetify.breakpoint.xs}" >
            <VCol class="pr-0" cols="auto">
              <div class="size-hours-header" :class="{'text-caption' :$vuetify.breakpoint.xs}">{{$t('cabinet.cost')}}:</div>
            </VCol>
            <VCol cols="auto">
              <div class="size-hours-price" :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ getTotalHours }} грн.</div>
            </VCol>
          </VRow>
        </VCol>
        <VCol  cols="12" xl="6" lg="6" md="6" sm="6">
          <VBtn text color="primary" :small="$vuetify.breakpoint.mdAndDown">
            <v-icon left :small="$vuetify.breakpoint.mdAndDown">
              mdi-cube-send
            </v-icon>
            
            {{$t('cabinet.departure')}}
          </VBtn>
          <VRow class="size-hours" align="center" :justify="$vuetify.breakpoint.xs ? 'space-between' :'start'" :no-gutters="$vuetify.breakpoint.xs" :class="{'px-8' :$vuetify.breakpoint.xs}" >
            <VCol class="pr-0 text-left"  cols="auto">
              <div class="size-hours-header" :class="{'text-caption' :$vuetify.breakpoint.xs}">{{$t('cabinet.sending')}}:</div>
            </VCol>
            <VCol  class="pr-0"  cols="auto">
              <div class="size-hours-data" :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ total || "0" }}</div>
            </VCol>
          </VRow>
          <VRow class="size-hours" align="center" :justify="$vuetify.breakpoint.xs ? 'space-between' :'start'"  :no-gutters="$vuetify.breakpoint.xs" :class="{'px-8' :$vuetify.breakpoint.xs}">
            <VCol class="pr-0 text-left"  cols="auto">
              <div class="size-hours-header" :class="{'text-caption' :$vuetify.breakpoint.xs}">{{$t('cabinet.cost')}}:</div>
            </VCol>
            <VCol  class="pr-0"  cols="auto">
              <div class="size-hours-price" :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ getTotalSend || "0" }} грн.</div>
            </VCol>
          </VRow>
        </VCol>
        <VCol cols="12" class="pa-0 ma-0">
          <VDivider :class="{'mt-4' :$vuetify.breakpoint.xs}"></VDivider>
        </VCol>
        <VRow justify="end" no-gutters >
          <VCol cols="12" >
            <VRow class="size-hours text-right" align="center">
              <VCol cold="auto" class="pr-0 "  :class="{'pb-0' :$vuetify.breakpoint.xs} ">
                <div class="size-hours-header"  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{$t('cabinet.total')}}:</div>
              </VCol>
              <VCol cols="auto" :class="{'pb-0' :$vuetify.breakpoint.xs}">
                <div class="size-hours-price"  :class="{'text-caption' :$vuetify.breakpoint.xs}">{{ getTotalPrice || "0" }} грн.</div>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VRow>
    </VCardText>
  </VCard>
</template>

<script>
import metricsService from "../../services/request/metrics/metricsService";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import loader from "../../mixins/loader";

export default {
  mixins: [user, notifications, loader],
  props: ["chosenDate", "total","userPlanInfo"],

  data: () => ({
    deportSizeHours: [],
    amount: "",
    year: new Date().toISOString().substr(0, 4),
    month: new Date().toISOString().substr(5, 2),
  }),
  async mounted() {
    console.log(123)
    if(this.userPlanInfo){
      this.getMetricsDeportSizeHours();
    }
  },

  watch: {
    chosenDate: {
      deep: true,
      handler() {
        this.onSelectDate();
      }
    },
    total:{
      deep:true,
      handler(e){
          this.totalPrice = Number(e) * Number(this.userPlanInfo.price_delivery)
          // console.log(this.userPlanInfo)
      }
    }
  },
  computed:{
    getDeportSizeHour(){
      if(this.deportSizeHours.h_m){
        return this.deportSizeHours.h_m.toFixed(2)
      }else{
        return 0
      }
    },
    getTotalSend(){
        if(this.total && this.userPlanInfo.price_delivery){
          return  Number(this.total) * Number(this.userPlanInfo.price_delivery)
        }else {
          return 0
          }
    },
    getTotalHours(){
        if(this.deportSizeHours.h_m && this.userPlanInfo.price_hour_meter){
          return (this.deportSizeHours.h_m * this.userPlanInfo.price_hour_meter).toFixed(2)
        }else {
          return 0
          }
    },
    getTotalPrice(){
      if(this.getTotalHours && this.getTotalSend){
        return Number(this.getTotalHours) + Number(this.getTotalSend)
      }else{
        return 0
      }
  }
  },
  methods: {
    async onSelectDate() {
      try {
        this.setLoading(true);
        if (this.chosenDate) {
          this.year = this.chosenDate.substr(0, 4);
          this.month = this.chosenDate.substr(5, 2);
          this.getMetricsDeportSizeHours();
        }
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    },
    async getMetricsDeportSizeHours() {
      try {
        if (this.permissions.client) {
          const params = [];
          params.year = this.year;
          params.month = this.month;
          this.deportSizeHours = await metricsService.getMetricsDeportSizeHours(params);

          // // orderDialog(this.deportSizeHours);
        }
      } catch (e) {
        this.setErrorNotification(e);
      }
    }
  }
};
</script>

<style lang="scss">
.size-hours-colum {
  h1 {
    color: #676767;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1.5rem;
}
.size-hours {
  width: 100%;
  margin: 0;
  justify-content: flex-start;
  &-header {
    padding: 0;
    color: black;
  }
  &-data {
    padding: 0;
    color: #676767;
  }
  &-price {
    color: #4fae3b;
  }
}
.ccol {
  width: auto;
  flex-grow: unset;
  flex-basis: unset;
  padding-right: 0;
}
.info-card {

min-height: 217px;
  height: auto;
}
</style>
