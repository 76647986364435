<template>
  <VMenu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="chosenDate"
    transition="scale-transition"
    offset-y
    max-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <VTextField
        class="capitalise"
        v-model="DateToText"
        :label="$t('form.date')"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        readonly
        hide-details=""
      ></VTextField>
    </template>
    <VDatePicker
      :locale="this.currentLanguage.code"
      :show-current="false"
      v-model="chosenDate"
      min="2021"
      :max="NextYear"
      no-title
      scrollable
      type="month"
    >
      <VSpacer></VSpacer>
      <VBtn text color="primary" @click="menu = false">
        Cancel
      </VBtn>
      <VBtn text color="primary" @click="onChangeDate">
        OK
      </VBtn>
    </VDatePicker>
  </VMenu>
</template>

<script>
import moment from "moment";
import loader from "../../mixins/loader";
import { mapGetters } from 'vuex';

export default {
  mixins: [loader],
  data: () => ({
    menu: false,
    chosenDate: new Date().toISOString().substr(0, 7),
    chosenDateTxt: moment(new Date().toISOString().substr(0, 7))
      .locale("uk")
      .format(" мммм YYYY року")
  }),
  methods: {
    onChangeDate() {
      // this.setLoading(true);
      setTimeout(() =>{
        this.$refs.menu.save(this.chosenDate);
      this.$emit("onChangeDate", this.chosenDate);
      // this.setLoading(false)
     },300)
    }
  },
  computed: {
    DateToText() {
      console.log()
      return moment(this.chosenDate)
        .locale(this.currentLanguage.key)
        .format(" MMMM YYYY року");
    },
    NextYear() {
      const event = new Date().toISOString().substr(0, 12);
      return event;
    },
    ...mapGetters([
      'currentLanguage'
    ])
  },

};
</script>

<style lang="scss">
.capitalise {
  input {
    text-transform: capitalize !important;
  }
}
</style>
