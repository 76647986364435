<template>
  <div>
    <v-tabs v-model="activeTab" class="manager-chart">
      <VRow no-gutters>
        <v-tab>{{ $t("cabinet.day") }}</v-tab>
        <v-tab>{{ $t("cabinet.month") }}</v-tab>
        <VSpacer />
        <div class="d-flex align-center justify-content-center">
          <div
            class="primary--text font-weight-bold"
            :class="{ 'text-caption text-center': $vuetify.breakpoint.xs }"
          >
            {{ `${$t("cabinet.total")} ${total}` || "--" }}
          </div>
        </div>
      </VRow>

      <v-tabs-items v-model="activeTab">
        <VDivider class="mb-3" />
        <v-tab-item>
          <DeliveredProducts
            :type="'day'"
            @total="TotalSend"
            :chosenDate="chosenDate"
            :id_state="id_state"
            v-if="activeTab === 0"
          ></DeliveredProducts>
        </v-tab-item>
        <v-tab-item>
          <DeliveredProducts
            :type="'month'"
            @total="TotalSend"
            :chosenDate="chosenDate"
            :id_state="id_state"
            v-if="activeTab === 1"
          ></DeliveredProducts>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import DeliveredProducts from "../charts/DeliveredProducts.vue";

export default {
  props: ["id_state", "chosenDate"],
  data: () => ({
    activeTab: 0,
    total: ""
  }),
  methods: {
    TotalSend(e) {
      this.total = e.substr(7);
      this.$emit("total", e);
    }
  },
  components: {
    DeliveredProducts
  }
};
</script>

<style></style>
