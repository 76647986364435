<template>
  <VCard class="my-2">
    <VCol class="pb-0">
      <VBtn
        text
        color="primary"
        :small="$vuetify.breakpoint.md"
        :x-small="$vuetify.breakpoint.smAndDown"
      >
        <VIcon left :small="$vuetify.breakpoint.md" :x-small="$vuetify.breakpoint.smAndDown">
          mdi-table-check
        </VIcon>
        {{ $t("cabinet.tableShipmentItemsTitle") }}
      </VBtn>
    </VCol>
    <VCardText>
      <v-simple-table class="my-table" :dense="!isLarge" v-if="items.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                <div class="header">
                  <span>{{ $t("table.photo") }}</span>
                </div>
              </th>
              <th class="text-left">
                <div class="header">
                  <span>{{ $t("table.name") }}</span>
                </div>
              </th>
              <th class="text-left">
                <div class="header">
                   <span>{{ $t("table.barCode") }}</span>
                </div>
              </th>
              <th class="text-left">
                <div class="header">
                   <span>{{ $t("table.amount") }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody name="fade" is="transition-group">
            <tr v-for="(product, index) in items" :key="index">
              <td class="x-sm">
                <img
                  v-if="!product.path_img"
                  :src="require('@/assets/images/supply-item.png')"
                  alt=""
                  class="image-placeholder"
                  :class="{ sm: !isLarge }"
                />
                <img
                  v-else
                  :src="product.path_img"
                  class="image"
                  @click="showImgDialog(product)"
                  v-show="isImgLoaded"
                  @load="showImg"
                  :class="{ sm: !isLarge }"
                />
                <template v-if="product.path_img && !isImgLoaded">
                  <VRow class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      :size="isLarge ? '20' : '10'"
                      :width="isLarge ? '2' : '1'"
                      color="green lighten-1"
                    ></v-progress-circular>
                  </VRow>
                </template>
              </td>
              <td>
                {{ product.name || "--" }}
              </td>
              <td>
                {{ product.bar_code || "--" }}
              </td>
              <td>
                {{ product.amount || "--" }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else class="text-center" :class="{ 'text-caption ': $vuetify.breakpoint.xs }">
        {{$t('cabinet.noShipmentItems')}}
      </div>
    </VCardText>
    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath" />
  </VCard>
</template>

<script>
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import metricService from "../../services/request/metrics/metricsService";
import { uniqueByUUID } from "../../mixins/helpers";
import loader from "../../mixins/loader";
import SizeUi from "../../mixins/SizeUi";
import imgDialog from "@/components/products/imgDialog.vue";

export default {
  components: {
    imgDialog
  },
  name: "ItemList",
  mixins: [user, notifications, loader, SizeUi],
  data: () => ({
    imgDialog: false,
    items: [],
    createDialog: false,
    imgpath: [],
    isImgLoaded: false,
    year: new Date().toISOString().substr(0, 4),
    month: new Date().toISOString().substr(5, 2)
  }),
  props: ["chosenDate"],
  mounted() {
    this.getItems();
  },
  watch: {
    chosenDate: {
      deep: true,
      handler() {
        this.onSelectDate();
      }
    }
  },
  methods: {
    showImgDialog(product) {
      this.imgpath = product.path_img;
      // orderDialog(this.imgpath);
      this.imgDialog = true;
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    async onSelectDate() {
      try {
        this.setLoading(true);
        if (this.chosenDate) {
          this.year = this.chosenDate.substr(0, 4);
          this.month = this.chosenDate.substr(5, 2);
          this.getItems();
        }
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    },
    async getItems() {
      try {
        const params = [];
        // orderDialog(this.chosenDate);
        params.year = this.year;
        params.month = this.month;
        const newItems = await metricService.getMetricsItems(params);
        this.items = newItems;
        // orderDialog(this.items.length);
      } catch (e) {
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    }
  }
};
</script>

<style lang="scss">
.image,
.image-placeholder {
  display: flex;
  align-items: center;
  object-fit: cover;
  border-radius: 5px;
  width: 60px;
  height: 50px;
  margin: 5px auto;

  transition: 0.3s ease;
  &-placeholder {
    border: 1px solid #e4e4e4;
  }
  &.sm {
    width: 30px;
    height: 25px;
  }
}
.image {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
</style>
