<template>
  <CabinetComponent />
</template>

<script>
import CabinetComponent from '../components/cabinet/CabinetComponent.vue';

export default {
  name: 'Cabinet',
  components: {
    CabinetComponent,
  },
};
</script>

<style >
body,html{
  overflow: hidden!important;
}
</style>
