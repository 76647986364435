<template>
  <VDialog v-model="visibility" max-width="1200" scrollable content-class="cross__dialog">
    <VCard>
      <VCardTitle>
        <VRow justify="space-between" align="center">
          <VCol :class="{'text-body-1' : $vuetify.breakpoint.sm,'text-caption' : $vuetify.breakpoint.xs}" cols="auto" class="pr-0">
           {{$t('cabinet.usersList')}}
          </VCol>
          <VSubheader class=" mr-2 text-capitalize" :class="{'text-caption' : $vuetify.breakpoint.xs}">
            {{ DateToText }}
          </VSubheader>
          <div class="cross__inner">
            <VBtn fab small color="white" elevation="0" @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </VBtn>
          </div>
        </VRow>
      </VCardTitle>
      <VCardText>
        <v-simple-table fixed-header height="600px" class="my-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-left md">
                  <VTextField
                    :label="$t('form.name')"
                    outlined
                    dense
                    hide-details
                    @input="changeFilter"
                    v-model="filter.name"
                    clearable
                  />
                </th>
                <th class="text-left md">
                  <VTextField
                    :label="$t('form.surname')"
                    outlined
                    dense
                    hide-details
                    @input="changeFilter"
                    v-model="filter.surname"
                    clearable
                  />
                </th>
                <th class="text-left">
                  <VTextField
                    :label="$t('form.email')"
                    outlined
                    dense
                    hide-details
                    @input="changeFilter"
                    v-model="filter.email"
                    clearable
                  />
                </th>
                <th class="text-left md">
                  <VTextField
                   :label="$t('form.phone_number')"
                    outlined
                    dense
                    hide-details
                    @input="changeFilter"
                    v-model="filter.phone"
                    clearable
                  />
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.id">
                <td v-if="user.path_img" class="text-center">
                  <v-avatar size="36">
                    <img :src="user.path_img" />
                  </v-avatar>
                </td>
                <td v-else class="text-center">
                  <v-avatar>
                    <v-icon x-large>
                      mdi-account-circle
                    </v-icon></v-avatar
                  >
                </td>
                <td>{{ user.name || "--" }}</td>
                <td>{{ user.surname || "--" }}</td>
                <td>{{ user.email || "--" }}</td>
                <td>{{ user.phone || "--" }}</td>
                <td>
                  <VBtn @click="onClientDetail(user)" small color="primary" class="white--text">
                    <v-icon left dense>mdi-chart-box-plus-outline</v-icon>
                    {{$t('form.more')}}
                  </VBtn>
                </td>
              </tr>
            </tbody>

          </template>
        </v-simple-table>
        <div class="text-center mt-3">
          <VBtn
            depressed
            color="blue"
            outlined
            dark
            v-if="!loadMoreDisabled"
            @click="nextPage"
            class="br-10"
          >
        {{$t('loadMore')}}
          </VBtn>
        </div>
      </VCardText>
      <VCardActions> </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import moment from "moment";
import usersService from "../../services/request/users/usersService";
import paginationMixin from "../../mixins/paginationMixin";
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import { mapGetters  } from 'vuex';

export default {
  props: {
    visible: {
      require: true
    },
    chosenDate: {
      require: false
    }
  },
  mixins: [loader, notifications, user, paginationMixin],

  data: () => ({
    filter: [],
    users: [],
    loadMoreDisabled: false,
    date: new Date().toISOString().substr(0, 7),
    mainTotal: "",
    totalPrice: ""
  }),
  watch: {
    chosenDate: {
      deep: true,
      handler() {
        // orderDialog(this.chosenDate);
        if (this.chosenDate) {
          // orderDialog(this.chosenDate);

          this.date = this.chosenDate;
        }
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    ...mapGetters([
      "currentLanguage"
    ]),
    DateToText() {
      return moment(this.date)
        .locale(this.currentLanguage.key)
        .format(" MMMM YYYY");
    }
  },
  mounted() {
    this.getUsersList();
  },
  methods: {
    async changeFilter() {
      try {
        const params = [];
        if (this.filter.name) {
          params.name = this.filter.name;
        }
        if (this.filter.surname) {
          params.surname = this.filter.surname;
        }
        if (this.filter.email) {
          params.email = this.filter.email;
        }
        if (this.filter.phone) {
          params.phone = this.filter.phone;
        }
        this.users = await usersService.getClientsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...params,
                      uselike: true
        });
        // orderDialog(params);
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async nextPage() {
      this.page += 1;
      await this.getUsersList();
    },
    async getUsersList(params) {
      if (this.permissions.can_see_client_reports) {
        try {
          const newItems = await usersService.getClientsList({
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,

          });

          if (newItems) {
            this.users.push(...newItems);
            this.loadMoreDisabled = newItems.length < this.perPage;
          }
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    },
    onClientDetail(e) {
      const params = [];
      params.uuid = e.uuid;
      params.date = this.chosenDate;
      this.$emit("showReportDialog", params);
    }
  }
};
</script>

<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
